<template>
  <div class="d-flex flex-column align-items-center ">
    <div class=" w-100 w-sm-75 mb-20 flex-column mt-20  d-flex align-items-center">
      <h1 class="text-center">Hello {{ currentUser.display_name }}</h1>
      <p class="text-center w-75">
        Welcome to the dashboard. Use this space to navigate to the different areas of the app
      </p>
    </div>
    <div class="row w-100 w-sm-75">
      <div class="col-md-5 col-12">
        <TrusteesCard class="card-stretch" :status="dashboard.trusteesStatus"></TrusteesCard>
      </div>
      <div class="col-md-7 col-12  mt-7">
        <div class="d-flex flex-wrap ">
          <div class="col-6 col-md-6">
            <MessageCard class="card-stretch" :type="'ready'" :count="dashboard.readyMessages"
              >Messages Ready to Send</MessageCard
            >
          </div>

          <div class="col-6 col-md-6">
            <MessageCard class="card-stretch" :type="'draft'" :count="dashboard.draftMessages"
              >Draft Messages</MessageCard
            >
          </div>

          <div class="col-6 col-md-6">
            <!-- begin:: manage profile -->

            <ProfileCard class="card-stretch"></ProfileCard>

            <!-- end:: manage profile -->
          </div>

          <div class="col-6 col-md-6">
            <NotificationsCard class="card-stretch"></NotificationsCard>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_DASHBOARD } from "@/core/services/store/user.module";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import TrusteesCard from "./components/TrusteesCard.vue";
import MessageCard from "./components/MessageCard.vue";
import ProfileCard from "./components/ProfileCard.vue";
import NotificationsCard from "./components/NotificationsCard.vue";

export default {
  name: "dashboard",
  components: {
    TrusteesCard,
    MessageCard,
    ProfileCard,
    NotificationsCard
  },

  data: function() {
    return {
      trusteesSwalShown: false
    };
  },

  computed: {
    ...mapGetters(["currentUser", "appName", "isAuthenticated", "dashboard"])
  },

  watch: {
    currentUser: function(newVal) {
      if (this.isAuthenticated == true) {
        // if user is authenticated and has no trustees, take them to the setup page
        if (!newVal.trustees && !this.trusteesSwalShown) {
          // avoid showing this dialog box twice
          this.trusteesSwalShown = true;

          // fire the pop up
          Swal.fire({
            text: `Hello ${newVal.display_name}, welcome to ${this.appName}.  The first step is to setup your trustees.  Trustees are people you entrust to unlock your account and dispatch your messages to loved ones once you have passed on.`,
            icon: "info",
            buttonsStyling: false,
            heightAuto: false,
            showCancelButton: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn font-weight-bold btn-light-primary",
              cancelButton: "btn font-weight-bold btn-default"
            }
          }).then(() => {
            this.$router.push({ name: "trustees.setup" });
          });
        }
      }
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);

    if (this.isAuthenticated == true) this.$store.dispatch(GET_DASHBOARD);
  },

  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },

    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },

    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};
</script>
