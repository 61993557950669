<template>
  <div class="card card-custom border border-light-info gutter-b bg-light-info">
    <div class="card-body">
      <div class="flex-column d-flex align-items-center">
        <router-link :to="{ name: 'profile' }" class="text-center h-100">
          <span class="svg-icon svg-icon-7x svg-icon-info mb-5">
            <!-- eslint-disable -->
            <!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo5/dist/../src/media/svg/icons/Communication/Adress-book2.svg--><svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <path
                  d="M18,2 L20,2 C21.6568542,2 23,3.34314575 23,5 L23,19 C23,20.6568542 21.6568542,22 20,22 L18,22 L18,2 Z"
                  fill="#000000"
                  opacity="0.3"
                />
                <path
                  d="M5,2 L17,2 C18.6568542,2 20,3.34314575 20,5 L20,19 C20,20.6568542 18.6568542,22 17,22 L5,22 C4.44771525,22 4,21.5522847 4,21 L4,3 C4,2.44771525 4.44771525,2 5,2 Z M12,11 C13.1045695,11 14,10.1045695 14,9 C14,7.8954305 13.1045695,7 12,7 C10.8954305,7 10,7.8954305 10,9 C10,10.1045695 10.8954305,11 12,11 Z M7.00036205,16.4995035 C6.98863236,16.6619875 7.26484009,17 7.4041679,17 C11.463736,17 14.5228466,17 16.5815,17 C16.9988413,17 17.0053266,16.6221713 16.9988413,16.5 C16.8360465,13.4332455 14.6506758,12 11.9907452,12 C9.36772908,12 7.21569918,13.5165724 7.00036205,16.4995035 Z"
                  fill="#000000"
                />
              </g></svg
            ><!--end::Svg Icon-->
            <!-- eslint-enable -->
          </span>
          <h4 class="text-center text-info mt-7">
            Update My Profile
          </h4>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProfileCard"
};
</script>
