<template>
  <div :class="'bg-light-' + color" class="card card-custom border">
    <div class="card-body">
      <div
        class="d-flex h-100 justify-content-end flex-column align-items-center"
      >
        <router-link :to="{ name: 'trustees' }" class="text-center">
          <img
            class="h-200px p-5"
            :src="
              status
                ? 'media/svg/illustrations/undraw-party.svg'
                : 'media/svg/illustrations/undraw-teams.svg'
            "
            alt=""
          />
        </router-link>
      </div>
    </div>
    <div class="card-footer bg-white d-flex flex-column align-items-center">
      <h3 class="text-center mt-0 mb-3 font-bold ">
        <i :class="titleIcon" class="fas mr-2"></i>{{ title }}
      </h3>
      <p v-if="status == false" class="text-center">
        Your trustees are not yet fully setup. Please click here for more info
      </p>
      <p v-else class="text-center">
        All your trustees have accepted their invitations. Your account is
        ready.
      </p>
      <router-link
        :to="{ name: 'trustees' }"
        :class="'btn-link-' + color"
        class="btn btn-link"
      >
        Go to Trustees
        <i class="fas fa-arrow-circle-right ml-2"></i>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrusteesCard",
  props: {
    status: Boolean
  },
  computed: {
    color() {
      return this.status == true ? "success" : "warning";
    },

    titleIcon() {
      return this.status == true
        ? "fa-check-circle text-success"
        : "fa-info-circle text-warning";
    },

    title() {
      return this.status == true ? "Trustees All Set" : "Check Your Trustees";
    }
  }
};
</script>
